<template>
  <v-app>
    <NavBar />
    <v-main id="mainFrame">
      <router-view :key="$route.path" />
    </v-main>

    <v-footer id="footer">
      <the-footer />
    </v-footer>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import NavBar from "@/components/NavBar";
import theFooter from "@/components/theFooter";

export default {
  name: "App",
  components: {
    NavBar,
    theFooter
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "Grimm-Müller: Porzellan freigedreht",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Hanna Grimm-Müller fertigt in Handarbeit Teller, Tassen und Vasen aus Porzellan. Die Keramik ist zum täglichen Gebrauch gedacht und wird hauptsächlich über Kunsthandwerkermärkte vertrieben."
        },
        {
          vmid: "keywords",
          name: "keywords",
          content:
            "Porzellan, freigedreht, Keramik, Kunsthandwerk, handgemacht, Teller, Tasse, Tassen, Vase, Blumenvase, Kerzenständer, Handwerk, Kunst, handbemalt, Töpferei, Ton, gedreht, Drehscheibe, Verkauf, spülmaschienenfest, Kunsthandwerkermarkt,"
        }
      ]
    };
  }
};
</script>

<style>
/* #mainFrame {
  max-width:1000px;
} */
#footer {
  display: flex;
  justify-content: center;
  background: darkgray;
  height: 100px;
}

.footer .v-btn {
  background-color: darkgray;
  margin: 0 40px;
}

.container {
  padding: 40px 0 !important;
}

@media screen and (min-width: 601px) {
  h2.responsive {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 600px) {
  h2.responsive {
    font-size: 35px !important;
  }
}
</style>
