export default {
  name: "Settings",
  user: {},
  backend: "https://hny6y5.deta.dev/",
  buildNumber: 0.2,
  //imageHost: "http://jrm.bplaced.net/grimm-mueller/imageHost/"
  //imageHost: "@/assets/img/"
  imageHost: "https://files.grimm-mueller.de/imageHost/",
  dummyData: () => import(/* webpackChunkName: "dummyData" */ "@/settings.js"),
  loginTokenDuration: 30 * 60 * 1000 // 30min in ms
};
