<template>
  <div>
    <section id="header">
      <v-parallax :src="imageHost + 'start4.jpg'" height="700">
        <v-container 
          fill-height
        >
          <v-img
            :src="imageHost + 'logo4.png'"
            id="logo"
            alt="Porzellan freigedreht"
            contain
            max-height="60%"
          />
        </v-container>
      </v-parallax>
    </section>

    <TextBlock textSlug="about" />
    <MarketOverview />
    <TextBlock textSlug="info" />
    <TheGallery />
    <!-- <TextBlock :textSlug="'ProduktGallerie'" /> -->
    <ProductGallery />
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import MarketOverview from "@/views/MarketOverview";
import TheGallery from "@/views/TheGallery";
import TextBlock from "@/views/textBlock";
import ProductGallery from "@/views/ProductGallery.vue";
import Settings from "@/settings.js";
import utils from "@/utils.js";

export default {
  name: "Home",

  components: {
    TextBlock,
    MarketOverview,
    TheGallery,
    ProductGallery
  },

  data() {
    return {
      imageHost: Settings.imageHost,
      frontPageImg: {}
    };
  },
  props: {},
  methods: {
    loadFrontPageImage() {
      this.frontPageImg = utils.loadImage("frontPage");
    }
  },
  mounted() {
    this.frontPageImg = utils.loadImage("frontPage");
  }
};
</script>

<style scoped>
.v-parallax__imagecontainer img {
  width: 120%;
}
</style>
