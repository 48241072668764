<template>
  <v-app-bar
    app
    :color="!$vuetify.breakpoint.mobile ? 'grey' : 'transparent'"
    :hide-on-scroll="!$vuetify.breakpoint.mobile"
    :id="[$vuetify.breakpoint.mobile ? 'nav_mobile' : 'nav']"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click="mobileMenu = !mobileMenu"
    >
    </v-app-bar-nav-icon>
    <ul
      :class="[$vuetify.breakpoint.mobile ? 'mobileNav' : 'nav-links']"
      v-if="!($vuetify.breakpoint.mobile && !mobileMenu)"
      @click="mobileMenu = false"
    >
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'Home', hash: '#header' }"
          >Start</v-btn
        >
      </li>
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'Home', hash: '#about' }"
          >Hanna Grimm-Müller</v-btn
        >
      </li>
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'Home', hash: '#maerkte' }">
          Märkte</v-btn
        >
      </li>
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'Home', hash: '#infoBlock' }"
          >Porzellan</v-btn
        >
      </li>
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'Home', hash: '#gallery' }"
          >Galerie</v-btn
        >
      </li>
      <!-- <li class="links">   
                <v-btn
                    text
                    color="dark-grey"
                    :to="{ name: 'Home', hash: '#produkte' }"
                    >Produkte</v-btn>
            </li> -->
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'Home', hash: '#footer' }"
          >Kontakt</v-btn
        >
      </li>
    </ul>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      mobileMenu: false
    };
  },
  mounted() {
    console.log(this.$vuetify.breakpoint.width);
    console.log("mobile:" + this.$vuetify.breakpoint.mobile);
  },
  methods: {}
};
</script>

<style scoped>
#nav {
  display: flex;
  justify-content: center;
  background: darkgray;
}
#nav_mobile {
  display: block;
  box-shadow: none;
}

.nav-links {
  display: flex;
  list-style: none;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}

#nav a {
  color: #ffffff;
  padding: 0 10px;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #ca0000;
  text-decoration: none;
}

.mobileNav {
  display: block;
  background-color: darkgray;
  position: absolute;
  top: 50px;
  list-style-type: none;
}
.v-toolbar__content {
  left: 0px;
}
</style>
