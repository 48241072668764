<template>
  <section id="produkte">
    <v-container class="text-center" height="500" justify="center">
      <text-block textSlug="gallery" />
      <!-- <h2 class="responsive">{{content.title}}</h2>

          <v-responsive
            class="mx-auto mb-8"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>
          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
           {{content.content}}
          </v-responsive> -->

      <!-- image thumbnails -->
      <v-row>
        <v-col
          v-for="(item, index) in images"
          :key="item.id"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="imgHostGallery + item.file"
            :lazy-src="imgHostLazy + item.file"
            aspect-ratio="1"
            class="grey lighten-2"
            @click="overlayImg = index+1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>

      <!-- overlay -->
      <v-overlay :value="overlayImg" opacity=".8">
        <v-img
          v-if="overlayImg"
          :src="imgHostGallery + overlayImageFile"
          :lazy-src="imgHostLazy + overlayImageFile"
          class="overlayImage"
          width="90vw"
          height="90vh"
          contain
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

        <v-btn
          @click="overlayImg = null"
          fab
          x-small
          class="overlayCloseButton mx-2"
        >
          x
        </v-btn>

        <v-btn
          @click="reduceOverlayImg"
          fab
          small
          class="overlayLastButton mx-2"
        >
          &lt;
        </v-btn>

        <v-btn
          @click="increasOverlayImg"
          fab
          small
          class="overlayNextButton mx-2"
        >
          &gt;
        </v-btn>
      </v-overlay>
    </v-container>
  </section>
</template>

<script>
import Settings from "@/settings.js";
import utils from "@/utils.js";
import TextBlock from "./textBlock.vue";

export default {
  name: "ProductGallery",
  components: {
    TextBlock
  },
  data() {
    return {
      imageHost: Settings.imageHost,
      overlayImg: null,
      content: {},
      images: []
    };
  },
  computed: {
    overlayImageFile() {
      //let theImg = this.images.find(img => img.order === this.overlayImg);
     console.log(this.overlayImg + ' - ' + this.images[this.overlayImg])
      let theImg = this.images[this.overlayImg-1]
      return theImg.file;
    },
    imgHostGallery() {
      return this.imageHost + 'products/1200/'
    },
    imgHostLazy() {
      return this.imageHost + 'products/200/'
    }
  },
  methods: {
    increasOverlayImg() {
      if (this.overlayImg >= this.images.length) {
        this.overlayImg = 1;
      } else {
        this.overlayImg++;
      }
      console.log(this.overlayImg);
    },

    reduceOverlayImg() {
      if (this.overlayImg === 1) {
        this.overlayImg = this.images.length;
      } else {
        this.overlayImg--;
      }
    },
    async loadImages() {
      this.images = await utils.loadSeveralImages("products");
      // sort by order:
      this.images.sort((a, b) => {
        return new Date(b.order) - new Date(a.order);
      });
    },
    async loadText() {
      this.content = await utils.loadTextBlock("gallery");
    }
  },
  mounted() {
    this.loadImages();
    this.loadText();
  }
};
</script>

<style>
.overlayCloseButton {
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 10;
}
.v-overlay__content {
  width: 90vw;
  height: 90vh;
}
</style>
