<template>
  <section :id="textSlug">
    <error-message v-for="(e, index) in errors" :error="e" :key="index" />

    <v-container class="text-center" height="500" justify="center">
      <h2 class="responsive">{{ content.title }}</h2>

      <v-responsive 
        class="mx-auto" 
        width="56"
        v-if="content.quote"
      >
        <v-divider class="mb-1"></v-divider>
        <v-divider></v-divider>
      </v-responsive>

      <v-responsive 
        class="textQuote font-italic font-weight-normal"
        v-if="content.quote"
        style="white-space: pre-wrap; "
      >
        {{content.quote}}
      </v-responsive>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>
        <v-divider></v-divider>
      </v-responsive>

      <v-responsive
        class="mx-auto title font-weight-light mb-8 px-5"
        max-width="720"
        style="white-space: pre-wrap;"
      >
        {{ content.content }}
      </v-responsive>
    </v-container>
  </section>
</template>

<script>
// import Settings from "@/settings.js"
// Dummydata for build only
import utils from "@/utils";
import ErrorMessage from "../components/errorMessage.vue";

export default {
  name: "textBlock",
  components: {
    ErrorMessage
  },
  data() {
    return {
      content: {
        slug: "loading",
        errors: [] // string
      }
    };
  },
  props: {
    textSlug: { type: String, required: true }
  },
  methods: {
    async loadTextBlocks() {
      try {
        this.content = await utils.loadTextBlock(this.textSlug);
        console.log("what we got: " + this.content.title);
        // this.content.content = this.content.content.replace(/\n\r?/g, '<br />')
        this.errors = [];
      } catch (e) {
        this.errors.append(e);
      }
    }
  },
  mounted() {
    this.loadTextBlocks();
  }
};
</script>
